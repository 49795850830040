import React, { useState, useEffect } from "react";
import EditWager from "./EditWager"; // Import the EditWager component
import parse from 'html-react-parser';

function WagerList({ wagers, updateWager, approveWager, user }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [participantFilter, setParticipantFilter] = useState("");
  const [editingWager, setEditingWager] = useState(null);
  const [showApprovalBanner, setShowApprovalBanner] = useState(false);

  const formatDatetime = (datetime) => {
    if (!datetime) return "";
    const date = new Date(datetime);
    return date.toLocaleString();
  };

  useEffect(() => {
    // Check if there are any pending approvals for the user
    const hasPendingApprovals = wagers.some(
      (wager) =>
        Array.isArray(wager.expand?.participants) &&
        wager.expand.participants.some((p) => p.id === user.id) &&
        !wager.approvals?.[user.username]?.approved
    );
    setShowApprovalBanner(hasPendingApprovals);
  }, [wagers, user.id, user.username]);

  // Filtered wagers based on search term, status, and participants
  const filteredWagers = wagers.filter((wager) => {
    const matchesTitle = wager.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = statusFilter ? wager.status === statusFilter : true;
    const matchesParticipant =
      participantFilter === "" ||
      wager.expand?.participants?.some(
        (participant) =>
          (participant.name || participant.email)
            .toLowerCase()
            .includes(participantFilter.toLowerCase())
      );

    return matchesTitle && matchesStatus && matchesParticipant;
  });

  const handleEdit = (wager) => {
    setEditingWager(wager);
  };

  const handleSave = async (updatedData) => {
    try {
      await updateWager(editingWager.id, updatedData);
      setEditingWager(null);
    } catch (error) {
      console.error("Error updating wager:", error);
    }
  };

  const handleCancel = () => {
    setEditingWager(null);
  };

  const handleApproval = async (wagerId, user) => {
    try {
      await approveWager(wagerId, user.username);
      setEditingWager(null);
    } catch (error) {
      console.error("Error updating wager:", error);
    }
  };

  return (
    <div className="container mt-4">
      {showApprovalBanner && (
        <div className="alert alert-warning text-center" role="alert">
          You have pending approvals to review!
        </div>
      )}

      <h2 className="text-center mb-4">Wager List</h2>

      {/* Filter Controls */}
      <div className="row mb-3">
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder="Search by title..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <select
            className="form-select"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">All Statuses</option>
            <option value="Open">Open</option>
            <option value="Settled">Settled</option>
            <option value="Canceled">Canceled</option>
          </select>
        </div>
        <div className="col-md-4">
          <select
            className="form-select"
            value={participantFilter}
            onChange={(e) => setParticipantFilter(e.target.value)}
          >
            <option value="">All Participants</option>
            {Array.from(
              new Set(
                wagers.flatMap((wager) =>
                  wager.expand?.participants?.map(
                    (participant) => participant.name || participant.email
                  )
                )
              )
            ).map((participant, index) => (
              <option key={index} value={participant}>
                {participant}
              </option>
            ))}
          </select>
        </div>
      </div>

      {editingWager ? (
        <EditWager
          wager={editingWager}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) : (
        <div className="row">
          {filteredWagers.map((wager) => (
            <div className="col-md-6 col-lg-4 mb-4" key={wager.id}>
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title d-flex justify-content-between align-items-center">
                    {wager.title}
                    <span
                      className={`badge ${
                        wager.status === "Open"
                          ? "bg-success"
                          : wager.status === "Settled"
                          ? "bg-secondary"
                          : wager.status === "Canceled"
                          ? "bg-danger"
                          : "bg-secondary"
                      }`}
                    >
                      {wager.status}
                    </span>
                  </h5>
                  <div className="card-text">
                    <strong>Participants:</strong>{" "}
                    {wager.expand?.participants
                      ?.map((participant) => participant.name || participant.email)
                      .join(", ") || "N/A"}{" "}
                    <br />
                    <strong>Terms:</strong> {parse(wager.terms)} <br />
                    <strong>Deadline:</strong> {formatDatetime(wager.deadline)} <br />
                    <strong>Stakes:</strong> {parse(wager.stakes)} <br />
                    <strong>Outcome:</strong>  {parse(wager.outcome || "N/A")} <br />
                    <strong>Created By:</strong>{" "}
                    {wager.expand?.createdby?.name || wager.expand?.createdby?.email || "N/A"}{" "}
                    <br />
                    <strong>Created:</strong> {formatDatetime(wager.created)} <br />
                    <strong>Approvals:</strong>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            <th>User</th>
                            {/* <th>Status</th> */}
                            <th>Timestamp</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(wager.approvals || {}).map(([username, data]) => (
                            <tr key={username}>
                              <td>{username}</td>
                              {/* <td>{data.approved ? 'Approved' : 'Pending'}</td> */}
                              <td>{data.timestamp ? new Date(data.timestamp).toLocaleString() : 'No Timestamp'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-end">
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={() => handleEdit(wager)}
                    disabled={wager.status !== "Open" || !(Array.isArray(wager.expand?.participants) &&
                      wager.expand.participants.some((p) => p.id === user.id))}
                    >
                    Edit
                  </button>
                  {Array.isArray(wager.expand?.participants) &&
                    wager.expand.participants.some((p) => p.id === user.id) &&
                    !wager.approvals?.[user.username]?.approved && (
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleApproval(wager.id,user)}
                      >
                        Approve
                      </button>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {filteredWagers.length === 0 && (
        <p className="text-center mt-3">No wagers match your filters.</p>
      )}
    </div>
  );
}

export default WagerList;
