import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import pb from "../pb";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      // Trigger password reset request
      await pb.collection("users").requestPasswordReset(email);
      setMessage(
        "Password reset email has been sent. Please check your inbox."
      );
      setError(null); // Clear any existing errors
    } catch (err) {
      console.error("Password reset error:", err.message);
      setError(err.message);
      setMessage(null); // Clear success message
    }
  };

  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <div
        className="card p-4 shadow-sm"
        style={{ maxWidth: "400px", width: "100%" }}
      >
        <h2 className="text-center mb-4">Reset Password</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        {message && <div className="alert alert-success">{message}</div>}
        <form onSubmit={handlePasswordReset}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">
            Send Reset Email
          </button>
        </form>
        <div className="text-center mt-3">
          <p>
            Remember your password?{" "}
            <span
              className="text-primary"
              role="button"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => navigate("/login")}
            >
              Login here
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
