import React, { useState, useEffect, useCallback } from "react";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import pb from "../pb";
import log from "../logger";

function WagerForm({ addWager, onClose }) {
  const [form, setForm] = useState({
    title: "",
    participants: [],
    status: "Open",
    terms: "",
    deadline: "",
    stakes: "",
    outcome: "",
  });
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!form.title) newErrors.title = "Title is required.";
    if (!form.participants.length) newErrors.participants = "Participants are required.";
    if (!form.status) newErrors.status = "Status is required.";
    if (!form.terms) newErrors.terms = "Terms are required.";
    if (!form.deadline) newErrors.deadline = "Deadline is required.";
    if (!form.stakes) newErrors.stakes = "Stakes are required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [form]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userList = await pb.collection("users").getFullList();
        setUsers(userList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    validateForm();
  }, [form, validateForm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleQuillChange = (name, value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleParticipantsChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
    setForm((prev) => ({ ...prev, participants: selectedOptions }));
    setErrors((prev) => ({ ...prev, participants: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const currentUserId = pb.authStore.model?.id;
    if (!currentUserId) {
      console.error("No user is logged in.");
      return;
    }

    const wagerData = {
      ...form,
      createdby: currentUserId,
    };

    const formatterDeadline = wagerData.deadline ? new Date(wagerData.deadline).toISOString() : wagerData.deadline;
    wagerData.deadline = formatterDeadline;

    log(wagerData);

    try {
      await addWager(wagerData);
      setForm({
        title: "",
        participants: [],
        status: "Open",
        terms: "",
        deadline: "",
        stakes: "",
        outcome: "",
      });
      setErrors({});
      onClose();
    } catch (error) {
      console.error("Error adding wager:", error);
    }
  };

  if (loading) {
    return <p>Loading users...</p>;
  }

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Create a Wager</h2>
      <form onSubmit={handleSubmit} className="card p-4 shadow-sm">
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Wager Title</label>
          <input
            type="text"
            id="title"
            name="title"
            className={`form-control ${errors.title ? "is-invalid" : ""}`}
            placeholder="Enter wager title"
            value={form.title}
            onChange={handleChange}
          />
          {errors.title && <div className="invalid-feedback">{errors.title}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="participants" className="form-label">Participants</label>
          <select
            id="participants"
            name="participants"
            className={`form-select ${errors.participants ? "is-invalid" : ""}`}
            multiple
            value={form.participants}
            onChange={handleParticipantsChange}
          >
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name || user.email}
              </option>
            ))}
          </select>
          {errors.participants && <div className="invalid-feedback">{errors.participants}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="terms" className="form-label">Terms</label>
          <ReactQuill
            id="terms"
            value={form.terms}
            onChange={(value) => handleQuillChange("terms", value)}
            className={`form-control ${errors.terms ? "is-invalid" : ""}`}
          />
          {errors.terms && <div className="invalid-feedback">{errors.terms}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="deadline" className="form-label">Deadline</label>
          <input
            type="datetime-local"
            id="deadline"
            name="deadline"
            className={`form-control ${errors.deadline ? "is-invalid" : ""}`}
            value={form.deadline}
            onChange={handleChange}
          />
          {errors.deadline && <div className="invalid-feedback">{errors.deadline}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="stakes" className="form-label">Stakes</label>
          <ReactQuill
            id="stakes"
            value={form.stakes}
            onChange={(value) => handleQuillChange("stakes", value)}
            className={`form-control ${errors.stakes ? "is-invalid" : ""}`}
          />
          {errors.stakes && <div className="invalid-feedback">{errors.stakes}</div>}
        </div>

        <button type="submit" className="btn btn-primary w-100">Add Wager</button>
      </form>
    </div>
  );
}

export default WagerForm;
